import { LoadableLazy } from '@confluence/loadable';

export { PageLabelsAutomationDiscovery } from '../src/PageLabelsAutomationDiscovery';
export { PagePublishAutomationDiscoveryDialog } from '../src/PagePublishAutomationDiscoveryDialog';
export { CheckSpaceAdminQuery } from '../src/CheckSpaceAdminQuery.graphql';
export { CreateAutomationRuleFromTemplateMutation } from '../src/CreateAutomationRuleFromTemplateMutation.experimentalgraphql';
export {
	type experimentalCreateAutomationRuleFromTemplateMutation,
	type experimentalCreateAutomationRuleFromTemplateMutationVariables,
	TemplateParameterTypeEnum,
} from '../src/__types__/CreateAutomationRuleFromTemplateMutation';
export { getAutomationStack } from '../src/utils/AutomationAPIUtilities';
export { doesADFContainJiraNodes } from '../src/utils/jira-detection';
export { markDiscoveryExperienceAsSeen } from '../src/utils/localStorage';
export { AutomationMenuSpotlight } from '../src/AutomationMenuSpotlight';
export { AutomationMenuSmartButtonSpotlight } from '../src/AutomationMenuSmartButtonSpotlight';
export { VerifiedContentSpotlight } from '../src/VerifiedContentAutomationSpotlight';
export { PagePublicLinkAutomationDiscoveryDialog } from '../src/PagePublicLinkAutomationDiscoveryDialog';
export { GlobalPublicLinkAutomationDiscoveryDialog } from '../src/GlobalPublicLinkAutomationDiscoveryDialog';
export { SpaceAssignedAutomationDiscoveryDialog } from '../src/SpaceAssignedAutomationDiscoveryDialog';
export { EventSourceName } from '../src/utils/analytics';
export { AutomationExperiments } from '../src/utils/feature-flags';
export { BulkArchiveDialog } from '../src/bulk-archive-pages/BulkArchiveDialog';
export type { BulkArchiveDialogProps } from '../src/bulk-archive-pages/BulkArchiveDialog';
export { isEligibleForWhiteboardDiscovery } from '../src/whiteboards/whiteboard-support';

export const LazyPageLabelsAutomationDiscovery = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PageLabelsAutomationDiscovery" */ '../src/PageLabelsAutomationDiscovery'
			)
		).PageLabelsAutomationDiscovery,
});

export const LazySlackDiscoveryDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-SlackDiscoveryDialog" */ '../src/SlackDiscoveryDialog'
			)
		).SlackDiscoveryDialog,
});

export const LazyBulkChangePageOwnerDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-BulkChangePageOwnerDialog" */ '../src/bulk-change-page-owner/BulkChangePageOwnerDialog'
			)
		).BulkChangePageOwnerDialog,
});

export const LazyPagePublishAutomationDiscoveryDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-PagePublishAutomationDiscoveryDialog" */ '../src/PagePublishAutomationDiscoveryDialog'
			)
		).PagePublishAutomationDiscoveryDialog,
});

export const LazyJiraLinkAutomationDiscoveryDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-JiraLinkAutomationDiscoveryDialog" */ '../src/JiraLinkAutomationDiscoveryDialog'
			)
		).JiraLinkAutomationDiscoveryDialog,
});

export const LazyWhiteBoardPublishDiscoveryDialog = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-WhiteBoardPublishDiscoveryDialog" */ '../src/whiteboards/WhiteBoardPublishDiscoveryDialog'
			)
		).WhiteBoardPublishDiscoveryDialog,
});

export const LazyAutomationDiscoveryHoverPageTitleMessage = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-AutomationDiscoveryHoverPageTitleMessage" */ '../src/AutomationDiscoveryHoverPageTitleMessage'
			)
		).AutomationDiscoveryHoverPageTitleMessage,
});

export const LazyArchivePageAutomationDiscovery = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ArchivePageAutomationDiscovery" */ '../src/ArchivePageAutomationDiscovery'
			)
		).ArchivePageAutomationDiscovery,
});
