export const ALLOWED_CONTENT_STATUSES = ['current', 'draft'];
export const EDIT_LABEL_DIALOG_HEADING_ID = 'edit-label-dialog-heading';
export enum ActionTypes {
	CLEAR = 'clear',
	CREATE_OPTION = 'create-option',
	REMOVE_VALUE = 'remove-value',
	POP_VALUE = 'pop-value',
	SELECT_OPTION = 'select-option',
}
export const INVALID_CHARACTERS = /[:;,.•?&[\]()#^*@!<>]/;
export enum ContentTypes {
	TEMPLATE = 'template',
}
